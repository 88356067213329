<template>
  <div>
    <div class="banner">
      <div class="container grid-banner">
        <div class="circle"></div>
        <div style="z-index:1">
          <div class="subtitle6 success">플랫폼파이 스킨</div>
          <div class="h6 main margin-top-16">플랫폼파이 솔루션 기반으로 제작된 플랫폼 서비스 스킨.<br>
            그대로 적용할 수도 있고 부분 수정개발도 가능해요</div>
          <div class="body2 sub2 margin-top-20">플랫폼파이 솔루션 기반으로 제작된 플랫폼 서비스 스킨.<br>
            그대로 적용할 수도 있고 부분 수정개발도 가능해요</div>
        </div>
      </div>
    </div>
    <div class="margin-top-60">
      <div class="container">
        <div class="h7 main margin-bottom-20">플랫폼파이 스킨</div>
        <div class="grid-theme">
          <card-product v-for="card in skins" :key="`card-${card.id}`" :card="card"></card-product>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardProduct from "../../../component/CardProduct";
  export default {
    name: "Skins",
    components: {CardProduct},
    created() {
      this.getData();
    },
    data() {
      return {
        skins: []
      }
    },
    methods: {
      getData() {
        let params = {
          category: 60,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,theme_version',
          page_num: 1,
          page_length: 10,
          ordering: 'priority'
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.skins = res.data.data;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .banner
    background-color $gray4
    padding 94px 0
    overflow hidden
  .grid-banner
    position relative
    display grid
    gap 60px
    grid-template-columns 1fr 502px

  .circle
    position absolute
    width 783px
    height 783px
    left 673px
    bottom -292px
    background #17FF90
    opacity 0.3
    filter blur(410px)
    z-index 0
  .grid-theme
    display grid
    grid-column-gap 36px
    grid-row-gap 48px
    grid-template-columns repeat(3, 1fr)
</style>
